
import { Form as Validation } from 'vee-validate'
import { defineComponent, ref } from 'vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import SignupPassword from '@/components/shared/SignupPassword.vue'

export default defineComponent({
  name: 'SignupInviteByEmail',
  components: {
    TmFormLine,
    TmButton,
    PageAuth,
    Validation,
    SignupPassword,
  },
  setup() {
    const signup = ref({
      firstName: '',
      lastName: '',
      password: '',
      country: 'uk',
    })

    return {
      signup,
    }
  },
})
